var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"business-management"},[_c('div',{staticClass:"title"},[_c('div',[_vm._v("供应商管理")]),_c('div',[_vm._v("总数："),_c('countTo',{attrs:{"startVal":0,"endVal":_vm.data.supplier.total,"duration":2000}})],1)]),_c('div',{staticClass:"box supplier"},[_c('base-croll-board',{attrs:{"data":_vm.data.supplier.list,"row-num":2},scopedSlots:_vm._u([{key:"props",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"index"},[_vm._v(_vm._s(item.index))]),_c('div',{staticClass:"company"},[_vm._v(_vm._s(item.shopName))]),_c('div',{staticClass:"region"},[_vm._v(_vm._s(item.countryName))])])]}}])})],1),_c('div',{staticClass:"title"},[_c('div',[_vm._v("客户管理")]),_c('div',[_vm._v("总数："),_c('countTo',{attrs:{"startVal":0,"endVal":_vm.data.customer.total,"duration":2000}})],1)]),_c('div',{staticClass:"box customer"},[_c('base-croll-board',{attrs:{"data":_vm.data.customer.list,"row-num":2},scopedSlots:_vm._u([{key:"props",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"index"},[_vm._v(_vm._s(item.index))]),_c('div',{staticClass:"company"},[_vm._v(_vm._s(item.shopName))]),_c('div',{staticClass:"region"},[_vm._v(_vm._s(item.countryName))])])]}}])})],1),_c('div',{staticClass:"title"},[_c('div',[_vm._v("从业人员管理")]),_c('div',[_vm._v("总数： "),_c('countTo',{attrs:{"startVal":0,"endVal":_vm.data.staff.total,"duration":2000}})],1)]),_c('div',{staticClass:"staff"},[_vm._m(0),_c('div',{staticClass:"box",staticStyle:{"height":"208px"}},[_c('div',{staticStyle:{"height":"0","border":"0 none"}}),_c('base-croll-board',{attrs:{"data":_vm.data.staff.list,"row-num":3},scopedSlots:_vm._u([{key:"props",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"index",staticStyle:{"margin-right":"0"}},[_vm._v(_vm._s(item.index))])]),_c('div',{staticClass:"company flex-4"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"company flex-4"},[_vm._v(_vm._s(item.mobile))]),_c('div',{staticClass:"company flex-4"},[_c('el-image',{staticStyle:{"width":"48px","height":"48px","border":"2px solid #30C6FE"},attrs:{"src":item.healthImg,"preview-src-list":[item.healthImg]}})],1),_c('div',{staticClass:"company flex-4"},[_vm._v(_vm._s(["正常", "即将过期", "过期"][item.status]))]),_c('div',{staticClass:"company flex-4"},[_vm._v(_vm._s(item.healthExpired))])])]}}])})],1)]),_c('div',{staticClass:"title"},[_c('div',[_vm._v("配送车辆管理")]),_c('div',[_vm._v("总数： "),_c('countTo',{attrs:{"startVal":0,"endVal":_vm.data.vehicle.total,"duration":2000}})],1)]),_c('div',{staticClass:"vehicle"},[_vm._m(1),_c('div',{staticClass:"box",staticStyle:{"height":"208px"}},[_c('div',{staticStyle:{"height":"0","border":"0 none"}}),_c('base-croll-board',{attrs:{"data":_vm.data.vehicle.list,"row-num":3},scopedSlots:_vm._u([{key:"props",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{key:index,staticClass:"item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleVehicleClick(item, index)}}},[_c('div',{staticClass:"company flex-4"},[_vm._v(_vm._s(item.carType))]),_c('div',{staticClass:"company flex-4"},[_vm._v(_vm._s(item.licensePlate))]),_c('div',{staticClass:"company flex-4"},[_vm._v(_vm._s(item.driver))]),_c('div',{staticClass:"company flex-4"},[_vm._v(_vm._s(item.status))])])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thead"},[_c('div',{staticClass:"flex-1"},[_vm._v("编号")]),_c('div',{staticClass:"flex-4"},[_vm._v("从业人员")]),_c('div',{staticClass:"flex-4"},[_vm._v("手机号码")]),_c('div',{staticClass:"flex-4"},[_vm._v("健康证")]),_c('div',{staticClass:"flex-4"},[_vm._v("健康证状态")]),_c('div',{staticClass:"flex-4"},[_vm._v("到期时间")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thead"},[_c('div',{staticClass:"flex-4"},[_vm._v("车辆类型")]),_c('div',{staticClass:"flex-4"},[_vm._v("车牌号")]),_c('div',{staticClass:"flex-4"},[_vm._v("驾驶员")]),_c('div',{staticClass:"flex-4"},[_vm._v("车辆状态")])])}]

export { render, staticRenderFns }